import styled from 'styled-components';
import { Snippets, LargeText } from 'components/styles';
import { Sass } from 'styled-icons/fa-brands/';
import { Css3 } from 'styled-icons/boxicons-logos/';
import { Html5 } from 'styled-icons/boxicons-logos/';
import { Calendar } from 'styled-icons/bootstrap';
import { ArrowheadRightOutline } from 'styled-icons/evaicons-outline';
import * as React from 'react';
export default {
  styled,
  Snippets,
  LargeText,
  Sass,
  Css3,
  Html5,
  Calendar,
  ArrowheadRightOutline,
  React
};